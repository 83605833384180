import { baseVipStore } from '@/store/vip'
import { getVipTierHeaderProductLine, getVipTierProductLine } from '@/constants/vip'

export function getVipCdValue(codeVal: string) {
  const { codeDictMap } = baseVipStore.getState()
  return getVipTierProductLine()?.[codeVal] || codeDictMap?.[codeVal]
}

export function getVipHeaderValue(codeVal: string) {
  return getVipTierHeaderProductLine()?.[codeVal]
}
